//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventList from "../components/EventList.vue";
import BaseButton from "../components/BaseButton.vue";

export default {
  name: "SchedulePage",
  components: {
    EventList,
    BaseButton
  },
  data() {
    return {
      drawer: false,
      newEvent: "",
      filterTypeSelected: "mostRecent",
      search: "",
      options: [
        { label: "Próximos", value: "next", color: "black" },
        { label: "Novos", value: "mostRecent", color: "black" },
      ]
    };
  },
  computed: {
    allTopics() {
      const eventsToShow = this.$store.getters.eventsFiltered;
      return eventsToShow;
    },
  },
  methods: {
    signUp() {
      this.$router.push({ name: "SignUp" });
    },
    filterThis(filterType) {
      this.filterTypeSelected = filterType;
    },
  },
};
